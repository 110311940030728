<template>
  <v-container class="LoginContainer" fill-height fluid>
    <nav>
      <v-app-bar color="white" flat app>
        <v-img max-width="90px" src="../../public/faviconrzhd.png" pl="0"></v-img>
        <v-toolbar-title class="ml-3">
          <div class="Header">Дирекция железнодорожных вокзалов —</div>
          <div class="text-none mr-3 font-family-RussianRail">филиал ОАО «РЖД»</div>
        </v-toolbar-title>
      </v-app-bar>
    </nav>
    <v-row align="center" justify="center">
      <v-col class="LoginForm pa-10" cols="8" md="6">
        <h1 align="center">Войти в ППЭУ ДЖВ</h1>
        <UserLoginForm :submitForm="submitAuthData" />
      </v-col>
    </v-row>
    <NotificationContainer />
  </v-container>
</template>

<script>
import UserLoginForm from '@/components/UserLoginForm';
import { mapActions, mapGetters } from 'vuex';
import NotificationContainer from '@/components/notification/NotificationContainer.vue';

export default {
  components: {
    NotificationContainer,
    UserLoginForm
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  methods: {
    async submitAuthData(username, password) {
      try {
        await this.login({ username, password });
        let nextPage = 'station';
        if (this.user.defaultPage && this.user.defaultPage.length > 0) {
          nextPage = this.user.defaultPage;
        }
        this.$router.push({ name: 'EscortReport' });
      } catch (ex) {
        console.log(ex);
      }
    },
    ...mapActions('auth', ['login'])
  }
};
</script>
<style scoped>
.LoginContainer {
  font-family: RussianRail;
  background-color: rgba(57, 74, 88, 0.2);
}
.LoginForm {
  border-radius: 3%;
  background-color: white;
}
</style>
